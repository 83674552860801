import { getConfigs, getEnv } from '@top/shared/src/configs'

import * as Sentry from '@sentry/react'

type InitSentryOptions = Pick<Sentry.BrowserOptions, 'ignoreErrors' | 'beforeSend'>

/** These the ports that we don't want to run sentry for in local dev. */
const localDevPorts = ['3000', '3001']

function getIsLocalServer() {
  const hostname = window.location.hostname
  const port = window.location.port

  return hostname === 'localhost' || localDevPorts.includes(port)
}

/**
 * Initializes the Sentry SDK with default BrowserTracing. The SDK is disabled in LOCAL DEV
 * environments!
 */
export function initSentry(options?: InitSentryOptions) {
  const { SENTRY_DSN } = getConfigs()

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing({ tracingOrigins: ['*'] })],

    ignoreErrors: options?.ignoreErrors,

    // Only report 25% of errors
    sampleRate: 0.25,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    beforeSend: options?.beforeSend,

    release: process.env.REACT_APP_VERSION ?? 'Unknown',
    environment: getEnv(),
    enabled: !getIsLocalServer(), // Enable in all envs NOT running locally!
  })
}
